import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Flex,
  Text,
  Image,
  ChakraProvider,
  extendTheme,
  useBreakpointValue,
} from "@chakra-ui/react";
import ProgressBar from "./components/ProgressBar";
import SocialMediaLinks from "./components/SocialMediaLinks";
import heart from "./assets/heart.png";
import beararmzLogo from "./assets/beararmzLogo.png";

const theme = extendTheme({
  breakpoints: {
    sm: "480px",
    md: "768px",
    lg: "992px",
    xl: "1280px",
  },
  styles: {
    global: {
      body: {
        bg: "#27272A",
        color: "white",
      },
    },
  },
});

const betaCompletionPercentage = 95;

const funnyDescriptions = [
  "Feeding The Koalas....We may have too many Quality Assurance Koalas.",
  "Koalas are taking a break...They’ll be back to coding soon!",
  "Just making sure the Koalas have enough snacks...Quality assurance is key!",
  "Work will continue after the dance battle has ended...it may take a number of weeks.",
  "Testing the coffee...Koalas need their caffeine fix!",
];

function App() {
  const [heartPosition, setHeartPosition] = useState({
    top: "10%",
    left: "10%",
    rotate: "0deg",
  });
  const [isVisible, setIsVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [uTilted, setUTilted] = useState(false);
  const [uDangling, setUDangling] = useState(false);
  const [uFellOff, setUFellOff] = useState(false);
  const [showChatBubble, setShowChatBubble] = useState(false);
  const [isRandomizing, setIsRandomizing] = useState(true);

  useEffect(() => {
    const positions = [
      { top: "10%", left: "10%", rotate: "0deg" },
      { top: "10%", right: "10%", rotate: "45deg" },
      { bottom: "10%", left: "10%", rotate: "0deg" },
      { bottom: "10%", right: "10%", rotate: "60deg" },
      { top: "20%", left: "50%", rotate: "-120deg" },
      { top: "20%", right: "50%", rotate: "15deg" },
      { top: "50%", left: "25%", rotate: "0deg" },
      { top: "50%", right: "25%", rotate: "0deg" },
      { top: "70%", left: "50%", rotate: "15deg" },
      { bottom: "50%", left: "50%", rotate: "180deg" },
    ];

    const changePosition = () => {
      if (isRandomizing) {
        const randomPosition =
          positions[Math.floor(Math.random() * positions.length)];
        setHeartPosition(randomPosition);
        setIsVisible(true);
        setUTilted(true);
        setIsAnimating(true);
        setTimeout(() => {
          setIsAnimating(false);
          setTimeout(() => setIsVisible(false), 1000);
        }, 5000);
      }
    };

    changePosition();
    const intervalId = setInterval(changePosition, 10000);

    return () => clearInterval(intervalId);
  }, [isRandomizing]);

  const [description, setDescription] = useState(() => {
    const randomDescription =
      funnyDescriptions[Math.floor(Math.random() * funnyDescriptions.length)];
    console.log("Initial Description:", randomDescription);
    return randomDescription;
  });

  const handleHeartClick = () => {
    if (uTilted) {
      setUDangling(true);
      setTimeout(() => {
        setUFellOff(true);
        setUDangling(false);
        setIsRandomizing(false);
        setShowChatBubble(true);
        setTimeout(() => {
          setShowChatBubble(false);
          setIsRandomizing(true);
        }, 4000);
      }, 2000);
    }
  };
  console.log("Description in App:", description); // Log description
  console.log("ProgressBar Props:", {
    progress: betaCompletionPercentage,
    description: description,
  }); // Log props being passed to ProgressBar

  return (
    <ChakraProvider theme={theme}>
      <style>
        {`
          @keyframes dangling {
            0% { transform: translateY(0); }
            50% { transform: translateY(10px); }
            100% { transform: translateY(0); }
          }
          @keyframes fall {
            0% { transform: translateY(0); }
            100% { transform: translateY(100vh); }
          }
        `}
      </style>

      <Box
        position="relative"
        minH="100vh"
        bg="white"
        p="4"
        borderRadius="8px"
        boxShadow="0 0 20px rgba(0, 0, 0, 0.2)"
        width="100%"
        overflow="hidden"
      >
        <Box
          position="relative"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          bg="#white"
          borderRadius="8px"
          p={4}
          width="100%"
          zIndex={1}
        >
          <Image
            src={beararmzLogo}
            alt="BearArmZ Logo"
            mb={4}
            boxSize={useBreakpointValue({ base: "150px", md: "200px" })}
            objectFit="contain"
          />
          <Heading
            as="h1"
            size={useBreakpointValue({ base: "xl", md: "2xl" })}
            mb={4}
            color="black"
            onClick={handleHeartClick}
            textAlign="center"
          >
            <span
              style={{
                display: "inline-block",
                transform: uTilted ? "rotate(-15deg)" : "rotate(0deg)",
                transition: "transform 0.5s",
                marginRight: "4px",
                animation: `${uDangling ? "dangling 2s infinite" : "none"}, ${
                  uFellOff ? "fall 2s forwards" : "none"
                }`,
                opacity: uFellOff ? 0 : 1,
              }}
            >
              U
            </span>
            nder Construction
          </Heading>

          <Text mb={4} maxWidth="600px" textAlign="center" color="black">
            BearArmZ is currently in development, and we are dedicated to
            creating an innovative self-defense delivery app. We invite
            investors and developers to stay tuned for updates as we progress
            towards our beta launch.
          </Text>
          <ProgressBar
            progress={betaCompletionPercentage}
            description={description}
          />
          <Box mt={8} textAlign="center">
            <Heading
              as="h2"
              size={useBreakpointValue({ base: "lg", md: "xl" })}
              mb={4}
              color="black"
            >
              Follow Us On Our Social Media
            </Heading>
          </Box>
          <SocialMediaLinks />

          <Text mt={6} maxWidth="600px" textAlign="center" color="black">
            For inquiries, including partnership opportunities or if you'd like
            to be a beta tester, please reach out to us at
            <Text ml={1} as="span" color="#0897a6">
              will@beararmzapp.com
            </Text>
            <Text mt={6} maxWidth="600px" textAlign="center" color="black">
              For BearArmZ Bellas Empowerment Program (BBEP), please email
            </Text>
            <Text ml={1} as="span" color="#0897a6">
              coco@beararmzapp.com
            </Text>
          </Text>
        </Box>
        {isVisible && (
          <Box
            position="absolute"
            top={heartPosition.top}
            left={heartPosition.left}
            style={{
              transform: `rotate(${heartPosition.rotate}) translateY(${
                isAnimating ? "0px" : "20px"
              })`,
              transition: "transform 3s ease, opacity 3s ease",
              opacity: isAnimating ? 1 : 0,
              zIndex: 2,
            }}
          >
            <Image
              src={heart}
              alt="Heart"
              onClick={handleHeartClick}
              style={{ cursor: "pointer" }}
            />
          </Box>
        )}
        {showChatBubble && (
          <Box
            position="absolute"
            top={`calc(${heartPosition.top} - 60px)`} // Adjusted positioning
            left={`calc(${heartPosition.left} + 60px)`} // Adjusted positioning
            transform="translate(-50%, -100%)"
            bg="turquoise"
            borderRadius="8px"
            boxShadow="0 0 10px rgba(0, 0, 0, 0.2)"
            p={3}
            zIndex={2}
          >
            <Text color="black">Thanks, One More Thing to Fix.</Text>
          </Box>
        )}
      </Box>
    </ChakraProvider>
  );
}

export default App;
