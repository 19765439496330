import React from "react";
import { VStack, HStack, IconButton, Text, Tooltip } from "@chakra-ui/react";
import { FaLinkedin, FaInstagram, FaFacebook } from "react-icons/fa";

const SocialMediaLinks = () => {
  const socialMediaData = [
    {
      name: "LinkedIn",
      icon: <FaLinkedin />,
      link: "https://www.linkedin.com/in/beararmz-technologies-409167307",
    },
    {
      name: "Instagram",
      icon: <FaInstagram />,
      link: "https://www.instagram.com/beararmzapp",
    },
    {
      name: "Facebook",
      icon: <FaFacebook />,
      link: "https://www.facebook.com/profile.php?id=61558943168232",
    },
  ];

  return (
    <HStack spacing={6} mt={6}>
      {socialMediaData.map((social) => (
        <VStack key={social.name} spacing={2} align="center">
          <Text fontSize="lg" fontWeight="medium" color="black">
            {social.name}
          </Text>
          <Tooltip label={social.name} hasArrow>
            <IconButton
              as="a"
              href={social.link}
              target="_blank"
              aria-label={social.name}
              icon={social.icon}
              colorScheme="blue"
              variant="solid"
              size="lg"
              _hover={{ transform: "scale(1.1)", boxShadow: "lg" }}
              _active={{ transform: "scale(0.95)" }}
            />
          </Tooltip>
        </VStack>
      ))}
    </HStack>
  );
};

export default SocialMediaLinks;
